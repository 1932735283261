@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
$font-1: "Roboto", sans-serif;
$font-2: "Roboto", sans-serif;
$text-small: 0.8rem;
$text-normal: 1rem;
$text-medium: 1.2rem;
$text-large: 2rem;
$color-1: #ea2193;
$color-2: #55f8eb;
$color-3: #f0aa27;
$color-3-l: #ee9cbb;
$white: #fff;
$color-1-d: #181415;
$color-1-l: #ffb9be;
$color-2-d: #171d1d;
$color-3-d: #1c1915;
$dark-1: #181a1b;
$dark-2: rgb(32, 35, 42);
$dark-3: #0f0e0e;
$dark-transparent: rgba(0, 0, 0, 0.356);
$dark-transparent-2: rgba(0, 0, 0, 0.7);
$deg-color-1-2-3: linear-gradient(
  150deg,
  $color-1 15%,
  $color-3 50%,
  $color-2 85%
);
$deg-logo: linear-gradient(90deg, $color-2 30%, $color-3 60%, $color-1 100%);

$deg-bg: linear-gradient(to right, #290a3d, #090e18);
$deg-bg2: linear-gradient(230deg, #31093d25, #0a0908);
$deg-dark: linear-gradient(to top, $dark-1 0%, #0e0e0e 100%);

$shadow-img: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.526));
$shadow-1: 0px 10px 20px #00000063;
$shadow-2: 0px 0px 10px rgba(0, 0, 0, 0.342);
$shadow-3: 0px 0px 20px 5px rgba(0, 0, 0, 0.44);

$glow-2: 0px 0px 7px 0px $color-2;
$glow-1: 0px 0px 7px 0px $color-1;
$glow-3: 0px 0px 7px 0px $color-3;
$glow-2-l: 0 0 40px 0px #0b3c50;
$glow-1-l: 0 0 40px 0px #500b3b;
$glow-3-l: 0 0 40px 0px #503a0b;
$glow-purple: 0px 0px 50px rgba(82, 15, 82, 0.411);

$shadow-3-bottom: 0px 15px 20px 5px rgba(0, 0, 0, 0.44);
$shadow-4: 0px 0px 20px 5px rgba(255, 255, 255, 0.43);
$text-shadow-1: 0px 0px 8px rgb(0 0 0 / 30%);
$text-shadow-2: 0px 0px 8px rgb(0 0 0 / 90%);
$text-shadow-3: 0px 2px 6px rgb(0 0 0 / 90%);
$shadow-tile: 0px 5px 9px 0px rgb(0 0 0 / 80%);
$shadow-tiletop: 0px -8px 9px 0px rgb(0 0 0 / 70%);

$transition-short: 200ms;
