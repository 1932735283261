@import '../../styles/variables';

.TrackRow {
  display: flex;
  flex-direction: row;
  height: 50px;
  border-bottom: 1px solid rgba(54, 2, 56, 0.103);

  &--active {
    .TrackRow__info {
      background: $color-2;
      color: white;

      &--artist,
      &--title {
        color: $dark-1;
      }
    }
  }

  &__picture {
    width: 50px;
    height: 50px;
    object-fit: cover;
    img {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: $deg-bg2;
    flex: 1;
    text-align: left;
    padding: 5px 10px;

    &--artist {
      font-size: $text-small;
      font-weight: bold;
      color: $color-2;
    }
    &--title {
      font-size: $text-small;
    }
  }
}
