@import '../../styles/variables';

.Legal {
  max-width: 600px;
  &__logo {
    width: 200px;
    margin: 10px auto;
    display: block;
  }
  padding: 20px;

  strong {
    font-weight: bold;
  }

  h2,
  h3 {
    margin-bottom: 10px;
  }
  h4 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 1rem;
  }
  a {
    color: $color-2;
  }
  li {
    list-style: inside;
    list-style-position: outside;
    margin-left: 2rem;
    margin-top: 5px;
  }
  ul {
    margin-left: 10px;
    margin-bottom: 20px;
  }
  // li:before {
  //   content: '•';
  //   color: $color-1;
  //   margin-right: 4px;
  // }
}
