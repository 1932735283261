@import '../../styles/variables';

.NotificationsContainer {
  display: flex;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  z-index: 6;
  position: absolute;
  top: 60px;
  right: 0;
  background: rgba(14, 14, 14, 0.85);
  backdrop-filter: blur(20px);
  color: $white;
  width: 100%;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 4;
  }
}
