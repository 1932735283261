@import '../styles/variables';

.Error {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  margin: 0 auto;
  padding: 10px 10px;
  border-radius: 10px;
  background: $deg-bg;
  font-size: 0.8rem;
  font-weight: normal;
  margin-bottom: 10px;

  svg {
    margin-right: 5px;
    color: $color-1;
  }

  &__content {
    // padding: 5px 10px 3px 3px;
  }
}
