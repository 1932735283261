@import '../../styles/variables';

.Notification {
  display: inline-flex;
  margin: 10px;
  width: 100%;
  max-width: 300px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: $white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: $shadow-tile;

  &__label {
    display: flex;
    margin: 0 10px 0 10px;

    align-items: center;
    font-size: $text-small;
    font-weight: bold;
    color: $dark-1;

    svg {
      margin-right: 10px;
    }
  }

  .Button {
    font-size: 0.8rem;
    margin-left: 45px;
    box-shadow: none;
    .Button__label {
      margin: 2px 5px 2px 10px;
    }
    // &:hover {
    //   background: $dark-1;
    //   color: $white;
    // }
    svg {
      margin: 0;
    }
  }
}

.NotificationRow {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: $dark-transparent-2;
  border-radius: 100px;
  margin-top: 15px;
  padding: 5px 10px 5px 0;
  box-shadow: $shadow-tile;

  &__label {
    display: flex;
    align-items: center;
    margin: 0 10px;
    font-size: 0.8rem;
    font-weight: bold;
    color: white;
    svg {
      color: $color-2;

      margin-right: 5px;
    }
  }

  .Button {
    font-size: 0.8rem;
    margin-left: 5px;
    margin-top: 4px;
    box-shadow: none;

    .Button__label {
      margin: 0 5px 0 10px;
    }
    // &:hover {
    //   background: $dark-1;
    //   color: $white;
    // }
    svg {
      margin: 0;
    }
  }
}
