@import '../../styles/variables';

@media only screen and (min-width: 481px) {
  .Toolbar {
    display: none !important;
  }
}
.Toolbar {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background: $deg-bg;
  box-shadow: $shadow-tiletop;
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  padding-top: 10px;
  height: calc(60px + env(safe-area-inset-bottom));

  &__margin {
    padding-top: 60px;
  }
}
