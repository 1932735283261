@import '../../styles/variables';

.WidgetContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $deg-bg2;
  max-width: 800px;
  width: 100%;
  height: 100%;
  min-height: 160px;
  margin: 5px auto;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  box-shadow: $glow-purple;

  &--glow {
    box-shadow: $glow-2 !important;
  }

  &__background {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__title {
    z-index: 10;
    display: inline-flex;
    flex-direction: column;
    font-size: $text-normal;
    margin: 0 auto 5px auto;
    font-family: $font-2;
    font-size: $text-large;
    // text-shadow: $text-shadow-1;

    // &:before,
    // &:after {
    //   content: '';
    //   height: 2px;
    //   flex: 1 0 20px;
    // }
    // &:before {
    //   margin: 5px 5px 0 20px;
    //   max-width: 50px;
    //   background: rgba(255, 255, 255, 0.1);
    // }
    // &:after {
    //   margin: 5px 20px 0 5px;
    //   max-width: 50px;

    //   background: rgba(255, 255, 255, 0.1);
    // }
  }
  &__subtitle {
    margin-bottom: 20px;
    text-align: center;
    // font-style: italic;
    font-size: $text-small;
    z-index: 1;
  }
  &__content {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }
}
