@import '../../styles/variables';

.UserLabel {
  width: 100%;
  height: 60%;
  display: flex;
  padding: 3px 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(23, 23, 23, 1) 100%
  );
  color: $white;
  text-shadow: $text-shadow-1;
  // font-variant: small-caps;
  &__name {
    font-size: 0.8rem;
    font-weight: bold;
  }

  &__score {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    font-weight: normal;

    color: $white;

    img {
      width: 10px;
      margin-left: 5px;
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.84));
    }
  }
  &__location {
    text-align: left;
    width: 100%;
    font-size: 0.6rem;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--distance {
      font-size: 0.6rem;
    }
  }
}
