@import '../../styles/variables';
.Stats {
  display: flex;
  justify-content: center;

  &__container {
    flex: 1;
    margin-top: 20px;
  }

  &__item {
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: space-between;

    &__number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $text-large;
      margin: 10px;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 50%;
      background: $deg-bg2;
      box-shadow: $glow-2-l;
      font-family: $font-2;
    }
    .TrackRow {
      flex: 1;
    }
  }

  &__artist {
    flex: 1;
  }
}
