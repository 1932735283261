@import '../styles/variables';

.Button {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $text-normal;
  font-weight: 400;
  border-radius: 50px;
  // box-shadow: $shadow-1;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background: $dark-transparent;
  border: 1px solid;

  svg,
  img {
    width: 25px;
    height: 25px;
    margin: 2px;
  }

  &__label {
    margin-right: 10px;
    margin-left: 5px;
  }

  &--big {
    svg,
    img {
      width: 40px !important;
      height: 40px !important;
    }
  }

  &--small {
    svg,
    img {
      width: 15px !important;
      height: 15px !important;
    }
    font-size: $text-small;
  }

  &--rounded {
    border-radius: 50px;
  }

  &--white {
    background: $white;
    color: $dark-1;
    text-shadow: none !important;
    border: none;

    svg,
    img {
      color: $dark-1;
      fill: $dark-1;
      filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.5)) !important;
    }
  }

  &--white--1 {
    background: $white;
    color: $color-1;
    svg,
    img {
      color: $color-1;
      fill: $color-1;
    }
  }

  &--dark {
    background: $dark-1;
    color: $white;
    border: none;

    svg,
    img {
      color: $white;
      fill: $white;
    }
  }

  // &:hover {
  //   background: $white;
  //   color: $dark-1;
  //   box-shadow: $shadow-tile;

  //   svg,
  //   img {
  //     color: $color-2;
  //     fill: $color-2;
  //   }
  // }

  &__badge {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 0.8rem;
    color: $white;
    background: $color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &--reverse {
    flex-direction: row-reverse;
    .Button__label {
      margin-left: 10px;
      margin-right: 5px;
    }
  }
}
