@import '../../styles/variables';

.UserRow {
  display: flex;
  align-items: center;
  color: white;
  width: 100%;

  &:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.03);
  }

  &__picture {
    width: 80px;
    height: 80px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__column {
    &:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.01);
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    &--large {
      flex: 2;
    }
    &--normal {
      flex: 1;
    }
    &--small {
      flex: none;
    }
  }

  &__info {
    margin-left: 5px;
    &--large {
    }
    &--small {
      flex: none;
    }
  }

  &__strLogo {
    width: 80px;
  }
}
