@import '../../styles/_variables.scss';

.Alert {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  padding: 20px;
  width: 100%;
  max-width: 300px;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  transition: all 0.2s ease-in-out;
  border-radius: 30px;

  &--hide {
    opacity: 0%;
    visibility: hidden;
    transform: translate(-50%, -50%) scale(0.9);
  }

  &__content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
