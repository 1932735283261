@import "../styles/variables";

.Landing {
  max-width: 1200px;
  margin: 0 auto;
  color: white;

  h3 {
    font-weight: normal;
  }

  hr {
    border: 1px solid $color-2;
    box-shadow: $glow-2;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;

    &--overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }
  }

  .logo {
    z-index: 2;
    position: relative;
    max-width: 300px;
    margin: 0px auto;
    display: block;
    filter: $shadow-img;
  }
  .slogan {
    padding: 10px;
    z-index: 2;
    margin-bottom: 30px;
    position: relative;
    font-family: $font-2;
    font-size: $text-large;
    text-align: center;
    text-shadow: $text-shadow-2;
  }
  .hero {
    padding: 80px 20px;
    top: 0px;
    // margin-left: -10px;
    // margin-right: -10px;
    background: $deg-bg2;
    font-family: $font-2;
    font-weight: 700;
    text-align: center;
    font-size: 3rem;

    &--em {
      color: $color-2;
    }
    &--em2 {
      color: $color-1;
    }

    &--announcement {
      padding: 20px;
      border: 2px solid $color-2;
      border-radius: 10px;
      margin: 100px 10px;
      max-width: 600px;
    }
  }

  .subhero {
    margin: 30px auto 20px auto;
    padding: 0 10px;
    max-width: 600px;
    font-size: $text-large;
  }

  .secondary-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .alt-section-container {
      display: flex;
      // align-items: center;
      // justify-content: center;
      width: 100%;
      max-width: 600px;
      margin: 120px 10px;

      q {
        font-size: $text-large;
        text-align: center;
      }

      &--reverse {
        flex-direction: row-reverse;
      }

      &__screenshot {
        width: 40%;
        max-width: 300px;
        border-radius: 30px;
        overflow: hidden;
      }

      &__content {
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        flex: 1;
        color: white;
        padding: 30px;
        font-size: $text-normal;
      }
      h2 {
        margin-bottom: 20px;
      }

      p {
        font-size: $text-medium;
        text-align: left;
      }
    }

    .section-container {
      position: relative;
      display: flex;
      flex-direction: column;
      max-width: 500px;
      width: 100%;
      margin: 120px 10px;
      overflow: hidden;

      &__content {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        padding: 20px 0px;
        background: none;
        color: white;
        flex: 1;
        // text-align: center;
      }
      &__img {
        width: 100%;
        max-height: 280px;
        max-width: 380px;
        object-fit: cover;
        margin: 0 auto;
      }
      &__title {
        display: flex;
        align-items: center;
        z-index: 200;
        position: absolute;
        font-size: $text-medium;
        top: 10px;
        left: 10px;
        text-align: center;
        // background: $dark-transparent;
        // text-shadow: $text-shadow-1;
      }
      p {
        font-size: $text-medium;
        margin-top: 10px;
        font-variant: normal;
        // text-align: center;
      }
    }
    .section-subtitle {
      font-size: $text-large;
      text-align: left;
      font-weight: bold;
      text-align: center;
    }
  }
  .button {
    color: $color-1;
    background: none;
    border: 1px solid $color-1;
    padding: 8px 15px;
    border-radius: 10px;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    text-shadow: $shadow-1;
    margin: 10px auto;
    transition: 200ms;

    // &:hover {
    //   color: $white;
    //   background: $color-2;
    // }
  }
  .big-button {
    text-align: center;
    width: 200px;
    margin: 30px auto 80px auto;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: $color-2;
    border: 2px solid $color-2;
    border-radius: 50px;
  }

  &__footer {
    overflow: hidden;
    position: relative;
    margin: 70px auto;
    text-shadow: $text-shadow-3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: white;
    // background: $deg-3;
    font-family: $font-2;
    padding-bottom: 30px;

    &__title {
      z-index: 2;
      font-size: 3rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      object-fit: cover;
    }

    .arrow {
      width: 40px;
    }
  }
}
