@import '../styles/variables';

.P404 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $deg-bg;
  color: white;
  padding: 20px;

  img {
    width: 100%;
    max-width: 400px;
  }
}
