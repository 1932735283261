@import '../styles/variables';

@media only screen and (max-width: 480px) {
  .Header__logo {
    display: none;
  }
  // .Header  {
  //   height: 90px !important;
  //   align-items: flex-end !important;
  // }
}
@media only screen and (min-width: 481px) {
  .Header__logomin {
    display: none;
  }
}

.Header {
  z-index: 10;
  display: flex;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  width: 100%;

  margin: 0 auto;
  height: calc(60px + env(safe-area-inset-top));
  justify-content: space-between;
  align-items: center;
  background: $dark-transparent;
  backdrop-filter: blur(20px);
  padding-top: calc(10px + env(safe-area-inset-top));

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
  }
  &__logo {
    max-width: 120px;
  }
  &__logomin {
    max-width: 30px;
  }
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    &__points {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-3;
      cursor: pointer;
    }

    .Button {
      margin-left: 4px;
      box-shadow: none;
      color: white;
      border: none;
      background: none;
      // &:hover {
      //   color: $dark-1;
      // }
    }
  }
}
