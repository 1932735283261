@import '../../styles/variables';

.UserThumb {
  margin: 5px;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 40% 40% 40% 0;
  box-shadow: $shadow-tile;
  border: 1px solid black;
  background-color: $deg-bg;

  &__blurry {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
  }
  &__overlay {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 200ms;
    background: transparent;

    // &:hover {
    //   background: rgba(0, 0, 0, 0.5);
    // }
  }
}
