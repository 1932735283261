@import '../../styles/variables';

.Admin {
  &__toolbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: $deg-bg;
    font-size: $text-large;
    box-shadow: $shadow-tile;
  }
  &__User {
    display: flex;
    align-items: flex-start;

    &--avatar {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--matchs {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
