@import '../../styles/variables';
.ToolbarButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
    color: $white;
  }

  &__label {
    font-size: $text-small;
    color: $white;
    font-weight: 400;
  }

  &--active {
    .ToolbarButton__label,
    svg {
      color: $color-1;
    }
  }
}
