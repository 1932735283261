@charset "utf-8";
@import "variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $font-1;
}

body {
  margin: 0rem auto;
  font-family: $font-1;
  font-size: 16px;
  color: $dark-1;
  font-weight: 400;

  // background-image: linear-gradient(to left, #181415 0%, #171d1d 100%);
  background: $deg-bg;
}
button {
  background: none;
  border: none;
}
input,
select,
textarea {
  font-size: 100%;
  font-family: $font-1;
  padding: 0;
  margin-bottom: 0;
  border: none;
  outline: none;

  &:focus {
    white: none;
  }
}

::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}

p {
  line-height: 1.6rem;
}

em {
  font-weight: bold;
}

q {
  display: block;
  quotes: "\201C""\201D""\2018""\2019";
  font-family: $font-2;
  border-radius: 10px;
  // font-variant-ligatures: no-common-ligatures;

  img {
    height: 0.8em;
  }
}

q:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: -5px;
  position: relative;
  top: 0.2em;
  font-size: 1.3em;
  font-family: $font-2;
  color: inherit;
}
q:after {
  content: close-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: 5px;
  position: relative;
  top: 0.2em;
  font-size: 1.3em;
  font-family: $font-2;
  color: inherit;
}

label {
  margin-left: 5px;
  font-weight: bold;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: $white;
  cursor: pointer;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

h2 {
  font-size: $text-large;
  font-weight: bold;
}

h3 {
  font-weight: bold;
  font-size: $text-medium;
}

h4 {
  font-weight: bold;
  font-size: $text-normal;
}

.b-color-1 {
  background: $color-1;
}
.b-color-2 {
  background: $color-2;
}
.b-color-3 {
  background: $color-3;
}
.b-color-3-l {
  background: $color-3-l;
}
.b-color-white {
  background: $white;
}
.b-color-1-d {
  background: $color-1-d;
}
.b-color-2-d {
  background: $color-2-d;
}
.b-color-3-d {
  background: $color-3-d;
}

.b-color-dark-1 {
  background: $dark-1;
}
.b-color-dark-transparent {
  background: $dark-transparent;
}
.b-color-dark-transparent-2 {
  background: $dark-transparent-2;
}
.b-color-3-deg {
  background: $deg-logo;
}

.b-color-white {
  background: $white;
}

.color-1 {
  color: $color-1;
}
.color-1-l {
  color: $color-1-l;
}
.color-2 {
  color: $color-2;
}
.color-3 {
  color: $color-3;
}
.color-3-l {
  color: $color-3-l;
}
.color-white {
  color: $white;
}
.color-dark {
  color: $dark-1 !important;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.text-shadow {
  text-shadow: $text-shadow-1;
}
.text-shadow-2 {
  text-shadow: $text-shadow-2;
}

.center {
  text-align: center;
}

.small {
  font-size: $text-small;
}
.large {
  font-size: $text-large;
}
.medium {
  font-size: $text-medium;
}
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

.hidden {
  opacity: 0;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mt5 {
  margin-top: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}

.mauto {
  margin: 0 auto;
}

.flex-h {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.flex-h-c {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-h-sa {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-v {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.flex-v-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.main-container {
  position: relative;
  max-width: 1200px;
  padding-top: calc(60px + env(safe-area-inset-top));
  left: 0;
  right: 0;
  top: 0;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  color: $white;
  padding-bottom: calc(60px + env(safe-area-inset-bottom));

  &--top {
    justify-content: flex-start;
  }
}

.buttondev {
  position: absolute;
  top: 0;
  left: 0;
}

.category-title {
  display: flex;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: $text-medium;
  // text-shadow: $text-shadow-1;
  font-variant: small-caps;
  font-variant-ligatures: no-common-ligatures;

  font-family: $font-2;
  color: $color-2;

  // &:before,
  // &:after {
  //   content: '';
  //   height: 2px;
  //   flex: 1 0 20px;
  // }
  // &:before {
  //   margin: 5px 5px 0 0;
  //   background: $color-2;
  // }
  // &:after {
  //   margin: 5px 0 0 5px;
  //   background: $color-2;
  // }
}

.fluo {
  display: inline-block;
  // clip-path: polygon(6px 10%, 100% 0%, calc(100% - 6px) 100%, 0% 100%);
  background: $white;
  color: #4e4747;
  padding: 7px 10px 5px 10px;
  // font-weight: bold;
  // font-style: italic;
  font-family: $font-2;
  border-radius: 5px;

  // text-transform: capitalize;

  &--1 {
    background: $color-1;
    color: #ffffff;
  }
  &--2 {
    background: $color-2;
    color: #ffffff;
  }
  &--3 {
    background: $color-3;
    color: #ffffff;
  }
  &--degbg {
    background: $deg-bg;
    color: #ffffff;
  }
  &--dark {
    background: $dark-3;
    color: $white;
  }
  &--3-l {
    background: linear-gradient(90deg, rgb(223, 202, 153) 0%, $color-3-l 100%);
    color: #ffffff;
    text-shadow: 0 0 12px black;
  }
}

.text-gradient {
  background: $deg-logo;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animate {
  &--bounce {
    animation: bounce 1s ease-in-out infinite;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.bubble {
  border-radius: 64% 36% 82% 18% / 16% 50% 50% 84%;
  overflow: hidden;
}
.bubble2 {
  border-radius: 35% 65% 29% 71% / 49% 53% 47% 51%;
  overflow: hidden;
}

.glow-2 {
  box-shadow: $glow-2;

  &-l {
    box-shadow: $glow-2-l;
  }
}

.glow-1 {
  box-shadow: $glow-1;

  &-l {
    box-shadow: $glow-1-l;
  }
}

.glow-3 {
  box-shadow: $glow-3;

  &-l {
    box-shadow: $glow-3-l;
  }
}
